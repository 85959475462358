<template>
  <div class="success">
    <div>
      <img
        src="@/assets/image/icon-cg.png"
        alt=""
      >
    </div>
    <div class="successText">
      恭喜你，注册成功
    </div>
    <div class="successTime">
      <span>{{ time }}秒</span> 后跳转至 <span
        class="span1"
        @click="gotoLogin"
      >登录页面</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  data() {
    return {
      time: 3,
    };
  },
  mounted() {
    setTimeout(this.countdown, 1000);
  },
  methods: {
    gotoLogin() {
      this.$router.push({ name: 'login' });
    },
    countdown() {
      if (this.time > 0) {
        this.time -= 1;
        setTimeout(this.countdown, 1000);
      } else {
        this.time = 0;
        this.gotoLogin();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.success {
  transform: translateY(100px);
  display: flex;
  flex-direction: column;
  text-align: center;
  div {
    margin: 10px 0;
   img {
      width: 60px;
      height: 60px;
      margin: 0 auto;
    }
  }
  .successText {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .successTime {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999;
    span {
      color: #007CE2;
    }
    .span1 {
      cursor: pointer;
    }
  }
}
</style>
