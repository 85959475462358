<template>
  <div class="bg">
    <div class="container">
      <div class="titleDocument">
        已有账号，<a @click="login">立即登录</a>
      </div>
      <div class="main">
        <PlateTitle
          title="用户注册"
        />
      </div>
      <Form v-if="!isSuccess" />
      <RegisterSuccess v-if="isSuccess" />
    </div>
  </div>
</template>

<script>

import PlateTitle from '@/components/PlateTitle';
import RegisterSuccess from './components/success.vue';
import Form from './components/form.vue';

export default {
  name: 'Register',
  components: {
    PlateTitle,
    RegisterSuccess,
    Form,
  },
  data() {
    return {
      isSuccess: false,
    };
  },
  mounted() {
    this.isSuccess = false;
  },
  methods: {
    login() {
      this.$router.push({ name: 'login' });
    },
    visSuccess() {
      this.isSuccess = true;
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  background-color: #F0F3F7;
  overflow: hidden;
}
.container{
  background-color: #fff;
  min-height: 676px;
  width: 1180px;
  margin: 30px auto 80px;
  position: relative;
  .titleDocument {
    position: absolute;
    top: 36px;
    right: 50px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}
.main{
  padding: 30px 50px 95px;
}
</style>
